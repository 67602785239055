import React from "react";
import DynamicHeader from "../components/DynamicHeader";
import ColorPicker from "../images/colorPicker.svg";
import Dropdown from "antd/lib/dropdown";
import Panel from "rc-color-picker/lib/Panel";
import "rc-color-picker/assets/index.css";
import { useNavigate } from "react-router-dom";
import useClientSubmit from "../hooks/useClientSubmit";
import ImageDefault from "../images/imgDefault.svg";
import Loading from "../components/Loading";
const RegisterPage = () => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    onSubmit,
    watch,
    imageSrc,
    setImageSrc,
    colorHtml,
    setColorHtml,
    loading,
    setImageUpload,
    percent,
  } = useClientSubmit();

  const handleUploadImage = (e) => {
    const file = e.target.files[0];
    setImageUpload(file);
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleColorChange = (color) => {
    setColorHtml(color);
  };

  const formValues = watch(["name", "website", "email"]);
  const isFormValid = formValues.every(
    (value) =>
      value && value.trim() !== "" && imageSrc !== ImageDefault && colorHtml
  );
  return (
    <>
      {loading ? (
        <>
          <DynamicHeader step={4} path="/home" loading={loading} />
          <Loading percent={percent} />
        </>
      ) : (
        <>
          <DynamicHeader step={3} path="/home" />
          <div className=" py-8 flex flex-col items-center mb-11">
            <div className=" w-[500px] flex flex-col items-center gap-[24px]">
              <div className=" flex flex-col items-center text-[#2D2D2D] gap-2">
                <h1 className=" text-xl font-semibold">Tạo hệ thống mới</h1>
                <p className=" text-sm font-light">
                  Vui lòng điền thông tin hệ thống của bạn
                </p>
              </div>
              <div className=" flex flex-col gap-4 self-start w-full">
                <div className=" flex gap-5">
                  <img
                    src={imageSrc}
                    alt="logo"
                    className=" w-[64px] h-[64px] rounded-full"
                  />
                  <div className=" flex flex-col gap-3">
                    <div className="">
                      <h1 className=" text-lg font-semibold">Tải hình ảnh</h1>
                      <p className=" text-sm font-light">
                        Tối thiểu 400x400px, PNG hoặc JPEG
                      </p>
                    </div>
                    <div className="">
                      <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        onChange={handleUploadImage}
                      />
                      <button
                        className=" border border-[#DEDEDE] rounded-[8px] p-[6px] text-sm font-normal"
                        onClick={() =>
                          document.getElementById("fileInput").click()
                        }
                      >
                        Tải lên
                      </button>
                    </div>
                  </div>
                </div>
                <div className=" text-sm font-medium flex flex-col gap-[6px]">
                  <h2>Mã màu logo</h2>
                  <div className=" flex h-[54px] gap-[10px] w-[136px] items-center border-[#8F8F8F33] border-[1px] rounded-[12px] p-[16px_16px_16px_8px] ">
                    <Dropdown
                      overlayStyle={{ zIndex: "100" }}
                      trigger={["click"]}
                      overlay={
                        <Panel
                          color={colorHtml}
                          enableAlpha={false}
                          onChange={(color) => handleColorChange(color.color)}
                        />
                      }
                    >
                      <div
                        style={{
                          background: colorHtml
                            ? colorHtml
                            : `url(${ColorPicker})`,
                        }}
                        className=" rounded-lg min-w-[38px] min-h-[38px]"
                      ></div>
                    </Dropdown>
                    <input
                      className=" w-full text-[14px] font-normal outline-none border-none placeholder:text-sm placeholder:text-[#A4A4A4] placeholder:font-normal"
                      type="text"
                      placeholder="#000000"
                      value={colorHtml}
                      onChange={(e) => setColorHtml(e.target.value)}
                      maxLength={7}
                    />
                  </div>
                </div>
                <div className=" text-sm font-medium flex flex-col gap-[6px]">
                  <h2>Tên trường</h2>
                  <input
                    className=" text-[14px] font-normal border-[#8F8F8F33] border-[1px] rounded-[12px] py-[13px] px-[16px] placeholder:text-sm placeholder:text-[#A4A4A4] placeholder:font-normal"
                    type="text"
                    placeholder="Ví dụ: Trường Tiểu học ABC"
                    {...register("name")}
                  />
                </div>
                <div className=" text-sm font-medium flex flex-col gap-[6px]">
                  <h2>Link website</h2>
                  <div className=" flex w-full">
                    <div className="text-[#A4A4A4] border-[#8F8F8F33] border-[1px] py-[13px] px-[12px] text-[14px] font-normal rounded-tl-[12px] rounded-bl-[12px]">
                      https://
                    </div>
                    <input
                      className=" text-[14px] w-full font-normal border-[#8F8F8F33] border-y-[1px] py-[13px] px-[16px] placeholder:text-sm placeholder:text-[#A4A4A4] placeholder:font-normal"
                      type="text"
                      placeholder="abc"
                      {...register("website")}
                    />
                    <div className="text-[#A4A4A4] border-[#8F8F8F33] border-[1px] py-[13px] px-[12px] text-[14px] font-normal rounded-tr-[12px] rounded-br-[12px]">
                      .vitrust.app
                    </div>
                  </div>
                </div>
                <div className=" text-sm font-medium flex flex-col gap-[6px]">
                  <h2>Email</h2>
                  <input
                    className=" text-[14px] font-normal border-[#8F8F8F33] rounded-[12px] border-[1px] py-[13px] px-[16px] placeholder:text-sm placeholder:text-[#A4A4A4] placeholder:font-normal"
                    type="text"
                    placeholder="Ví dụ: example@email.com"
                    {...register("email")}
                  />
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="  mt-2 flex gap-[16px] justify-end">
                    <button
                      className=" rounded-lg bg-transparent border-[#E2E4E9] border-[1px] py-3 px-10 text-black text-[14px] font-semibold"
                      onClick={() => navigate("/function")}
                    >
                      Quay lại
                    </button>
                    <button
                      type="submit"
                      disabled={!isFormValid}
                      style={{
                        background: isFormValid ? "#20B73B" : "#A4A4A4",
                      }}
                      className=" rounded-lg py-3 px-10 text-white text-[14px] font-semibold"
                    >
                      Tạo
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default RegisterPage;
