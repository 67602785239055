import React from "react";
import Logo from "../images/Logo.svg";
import ButtonMain from "./ButtonMain";
import { useNavigate } from "react-router-dom";
const Header = () => {
  const navigate = useNavigate();
  return (
    <header>
      <div className=" w-screen bg-white py-[12px] px-[80px] flex items-center">
        <div className="flex justify-between w-full items-center">
          <div className="flex">
            <img src={Logo} alt="Logo" className="w-[93px] h-[36px] cursor-pointer" onClick={() => navigate('/home')}/>
            <div className=" w-[1px] h-[36px] mx-8 bg-black"></div>
            <div className="flex items-center text-[#2D2D2D] gap-8">
              <div className=" text-[16px] font-semibold leading-5 cursor-pointer" onClick={() => navigate('/home')}>
                <p>Trang chủ</p>
              </div>
              <div className=" text-[16px] font-semibold leading-5 cursor-pointer">
                <p>Hình ảnh</p>
              </div>
              <div className=" text-[16px] font-semibold leading-5 cursor-pointer">
                <p>Ứng dụng</p>
              </div>
              <div className=" text-[16px] font-semibold leading-5 cursor-pointer">
                <p>Quản lý</p>
              </div>
            </div>
          </div>
          <div className="flex items-center">
            <ButtonMain
              content="Trải nghiệm ngay"
              textSize="16px"
              fontWeight="500"
              path="/school"
            />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
