import React from "react";

const ButtonOutline = ({ content }) => {
  return (
    <button className=" rounded-lg bg-transparent border-[#20B73B] border-[1px] py-3 px-6 text-[#20B73B] text-[18px] font-semibold">
      {content}
    </button>
  );
};

export default ButtonOutline;
