import React from "react";

const Footer = () => {
  return (
    <div className=" bg-[#008C44] fixed bottom-0 w-full h-[42px] px-20 flex items-center justify-between">
      <p className=" text-white font-light text-sm">
        @ Bản quyền thuộc về Ngân hàng Phương Đông
      </p>
      <div className="  font-light text-sm flex gap-[10px] items-center">
        <p className="text-white">Quy định sử dụng</p>
        <div className="w-[1px] h-[16px] bg-[#FFFFFF33]"></div>
        <p className="text-white">Chính sách bảo mật</p>
      </div>
    </div>
  );
};

export default Footer;
