import React, { useState } from "react";
import DynamicHeader from "../components/DynamicHeader";
import FunctionList from "../components/funtion_page/FunctionList";
import { useNavigate } from "react-router-dom";
import student from "./../images/funtion_image/student.png";
import report from "./../images/funtion_image/report.png";
import fee from "./../images/funtion_image/fee.png";
import course from "./../images/funtion_image/course.png";
import teacherevaluetion from "./../images/funtion_image/evaluation.png";
import timekeeping from "./../images/funtion_image/attendance.png";
import library from "./../images/funtion_image/library.png";
import test from "./../images/funtion_image/test.png";
import event from "./../images/funtion_image/event.png";
import health from "./../images/funtion_image/health.png";
import schedule from "./../images/funtion_image/schedule.png";
import exercise from "./../images/funtion_image/exercise.png";

const FunctionPage = () => {
  const navigate = useNavigate();
  const data = sessionStorage.getItem('functions');
  const [functionsAdvance, setFunctionAdvance] = useState(data? data.split(",").map((item) => parseInt(item)):[])
  const functionBase = [
    {
      id: 1,
      name: "Quản lý sinh viên, học sinh",
      name_en: "(Student Management)",
      image: student
    },
    {
      id: 2,
      name: "In báo cáo",
      name_en: "(Reports Printing)",
      image: report
    },
    {
      id: 3,
      name: "Quản lý phí",
      name_en: "(Fees Management)",
      image: fee
    },
    {
      id: 4,
      name: "Quản lý khóa học",
      name_en: "(Coursces Management)",
      image: course
    }
  ]

  const functionAdvance = [
    {
      id: 5,
      name: "Quản lý thư viện",
      name_en: "(Library Management)",
      image: library
    },
    {
      id: 6,
      name: "Quản lý sự kiện",
      name_en: "(Event Management)",
      image: event
    },
    {
      id: 7,
      name: "Quản lý chấm công",
      name_en: "(Attendance Management)",
      image: timekeeping
    },
    {
      id: 8,
      name: "Quản lý sức khỏe",
      name_en: "(Health Management)",
      image: health
    },
    {
      id: 9,
      name: "Thời khóa biểu của sinh viên",
      name_en: "(Student Timetable)",
      image: schedule
    },
    {
      id: 10,
      name: "Quản lý kỳ thi",
      name_en: "(Exam Management)",
      image: test
    },
    {
      id: 11,
      name: "Quản lý bài tập",
      name_en: "(Exam Management)",
      image: exercise
    },
    {
      id: 12,
      name: "Đánh giá của giáo viên",
      name_en: "(Teacher's Evaluation)",
      image: teacherevaluetion
    }
  ]
  
  const handleContinue = () => {
    sessionStorage.setItem("functions", functionsAdvance)
    navigate("/register")
  }

  return (
    <>
      <DynamicHeader step={2} />
      <div className="flex flex-col items-center mt-[40px] mb-[82px]">
        <div className="mb-6 inline-grid">
          <FunctionList key={1} status="disable" className="mb-6" functions={functionBase} readonly={true} title="Tính năng cơ bản" sub_title="Các tính năng mặc định sẵn có sau khi bạn đăng ký"></FunctionList>
        </div>
        <div className="inline-grid"><FunctionList onChangeValue={setFunctionAdvance} value={functionsAdvance} key={2} status="default" functions={functionAdvance} readonly={false} title="Tính năng nâng cao" sub_title="Vui lòng chọn các tính năng mà bạn muốn thêm/xóa bằng cách bấm chọn vào nó"></FunctionList>
          <div className="mt-8 flex justify-end">
            <button
              className=" text-sm rounded-lg border border-[#E2E4E9] py-3 px-8 me-4 shadow-[0px_1px_2px_0px_rgba(82, 88, 102, 0.06)] leading-[16.94px] font-semibold"
              onClick={() => navigate("/school")}
            >
              Quay lại
            </button>
            <button
              className=" text-sm rounded-lg bg-[#20B73B] py-3 px-8 text-white font-normal"
              onClick={() => handleContinue()}
            >
              Tiếp tục
            </button>
          </div>
        </div>


      </div>
    </>
  );
};

export default FunctionPage;
