import requests from "./httpService";
import { registerPath, databaseSaas } from "./endpoint";
const ClientService = {
  registerSaas: async (body) => {
    return requests.post(registerPath + '?db=' + databaseSaas, body, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

export default ClientService;
