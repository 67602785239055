import React, { useState, useEffect } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const Loading = ({percent}) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prev) => {
        if (prev < percent) {
          return prev + 1;
        } else {
          clearInterval(interval);
          return prev;
        }
      });
    }, 20); 

    return () => clearInterval(interval);
  }, [percent]);
  return (
    <div className="flex flex-col items-center justify-center h-[90vh] gap-8">
      <div className=" w-[240px] h-[240px]">
        <CircularProgressbar
          value={progress}
          text={`${progress}%`}
          styles={buildStyles({
            rotation: 0.5,
            pathColor: "#1fb73b",
            textColor: "#1fb73b",
          })}
        />
      </div>
      <div className=" text-center flex flex-col gap-2">
        <h1 className=" text-xl font-semibold">Đang tạo hệ thống...</h1>
        <p className=" text-sm font-normal">Vui lòng đợi một vài giây</p>
      </div>
    </div>
  );
};

export default Loading;
