import React, { useEffect, useState } from "react";

const FunctionDisable = (props) => {
  return (
    <div className="bg-[#F9F9F9] w-[218px] h-[160px] flex items-center justify-center relative rounded-2xl p-2.5 hover:cursor-pointer select-none">
      <input value={props.funtion.id} className={`absolute mt-[13px] rounded-[2.6px] text-white me-[13px] top-0 right-0 shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)] w-[16px] h-[16px]  ${props.readonly ? "hidden" : ''}`} type="checkbox" />
      <div className="flex flex-col items-center">
        <div className="center mb-[12px]"><img src={props.funtion.image} className="w-15 h-15 mx-auto" alt="" /></div>
        <div className="text-sm text-center text-[#666666]">{props.funtion.name}</div>
        <div className="text-sm text-center text-[#666666]">{props.funtion.name_en}</div>
      </div>
    </div>
  );
};
const Function = (props) => {
  const check = props.value?.includes(props.funtion.id)
  const [checked, setChecked] = useState(check)

  let functions = props.value ? props.value : []

  const handleChange = (value) => {
    if (checked) {
      const funtionsSet = new Set([...functions, value])
      functions = Array.from(funtionsSet)
    }
    else {
      functions = functions.filter((item) => item !== value)
    }
    props.onChangeValue(functions)
  }

  useEffect(() => {
    handleChange(props.funtion.id)
  }, [checked])

  return (
    <div className={`bg-[#FFFFFF] w-[218px] h-[160px]
    flex items-center justify-center relative rounded-2xl p-2.5 hover:cursor-pointer select-none
    ${checked ? "bg-[#20B73B0A] border-2 border-[#20B73B]" : "border border-[#E2E4E9] hover:border-[#E2E4E9] hover:shadow-custom-hover"}
    `} onClick={() => setChecked(!checked)}>
      <input value={props.funtion.id} className={`absolute mt-[13px]
      appearance-none
      w-[16px] h-[16px]
      bg-white
      border-2 border-gray-300
      rounded-[4px] 
      me-[13px] 
      top-0 right-0
      cursor-pointer
      shadow-[0_35px_60px_-15px_rgba(0,0,0,0.3)]
      ${props.readonly ? "hidden" : ''}
      checked:bg-[#20B73B]
      checked:text-white
      checked:border-[#20B73B]
      after:content-[''] 
      after:cursor-pointer 
      after:w-[4px] after:h-[8px] 
      after:border-r-[2px] after:border-b-[2px] 
      after:border-white
      after:hidden
      checked:after:block
      after:relative
      after:top-[5px]
      after:left-[6.2px]
      after:transform after:-translate-x-1/2 after:-translate-y-1/2 after:rotate-45`} type="checkbox" checked={checked} />
      <div className="flex flex-col items-center">
        <div className="center mb-[12px]"><img src={props.funtion.image} className="w-15 h-15 mx-auto" alt="" /></div>
        <div className="text-sm text-center text-[#666666]">{props.funtion.name}</div>
        <div className="text-sm text-center text-[#666666]">{props.funtion.name_en}</div>
      </div>
    </div>
  );
};

export default FunctionDisable;
export { Function };
