import React from "react";
import FunctionDisable, {Function} from "./Function";

const FunctionList = (props) => {
  return (
    <div className="inline-grid">
      <div className="mb-[14px] flex flex-col items-center">
        <div class="text-[18px] max-w-[400px] font-semibold text-center mb-[8px]">
          {props.title}
        </div>
        <div className="text-sm max-w-[400px] text-center">{props.sub_title}</div>
      </div>
      <div className="inline-grid lg:grid-cols-4 sm:grid-cols-2 gap-4 grid-cols-2">
        {
          props.status === "default" ?
            props.functions.map((funtion, index) => <Function key={index} onChangeValue={props.onChangeValue} value={props.value} status={props.default} funtion={funtion} readonly={props.readonly}></Function>)
            : props.status === "disable" ? props.functions.map((funtion, index) => <FunctionDisable key={index} status={props.default} funtion={funtion} readonly={props.readonly}></FunctionDisable>) : (<></>)
        }
      </div>
    </div>
  );
};

export default FunctionList;
