import { useState } from "react";
import { useForm } from "react-hook-form";
import ImageDefault from "../images/imgDefault.svg";
import ClientService from "../services/clientService";
import { useNavigate } from "react-router-dom";
const useClientSubmit = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [percent, setPercent] = useState(0);
  const [imageSrc, setImageSrc] = useState(ImageDefault);
  const [imageUpload, setImageUpload] = useState(false);
  const [colorHtml, setColorHtml] = useState();
  const { register, watch, handleSubmit } = useForm();

  const onSubmit = (data) => {
    setLoading(true);
    setPercent(80);
    const formData = new FormData();
    formData.append("email", data?.email);
    formData.append("ufile", imageUpload);
    formData.append("primary_color", colorHtml);
    formData.append("school_name", data?.name);
    formData.append("web_domain", data?.website);

    ClientService.registerSaas(formData)
      .then((res) => {
        setPercent(100); 
        setTimeout(() => {
          navigate(`/welcome?email=${data?.email}&name=${data?.name}`);
        }, 1000);
      })
      .catch((err) => {
        console.log("🚀 ~ .catch ~ err:", err);
      })
  };

  return {
    onSubmit,
    register,
    handleSubmit,
    watch,
    imageSrc,
    setImageSrc,
    imageUpload,
    setImageUpload,
    colorHtml,
    setColorHtml,
    loading,
    percent,
  };
};

export default useClientSubmit;
