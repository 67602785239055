import React from "react";
import { useNavigate } from "react-router-dom";
const ButtonMain = ({ content, textSize, fontWeight, path }) => {
  const navigate = useNavigate();
  return (
    <button
      style={{ fontSize: textSize, fontWeight: fontWeight }}
      className=" rounded-lg bg-[#20B73B] py-3 px-6 text-white font-light"
      onClick={() => navigate(path)}
    >
      {content}
    </button>
  );
};

export default ButtonMain;
