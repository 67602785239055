import React from "react";
import Logo from "../images/Logo.svg";
import { useNavigate } from "react-router-dom";
import ArrowLeft from "../images/arrowRight.svg";
import CheckDone from "../images/check.svg";
const DynamicHeader = ({ step, path, loading = false }) => {
  const styleNumber = {
    background: "#20B73B",
    color: "#FFFFFF",
    border: "None",
  };
  const styleContent = {
    color: "#2D2D2D",
  };
  const navigate = useNavigate();
  return (
    <header>
      <div className=" w-screen bg-white py-[12px] px-[80px] flex items-center">
        <div className="flex justify-between w-full items-center">
          <div className="flex">
            <img
              src={Logo}
              alt="Logo"
              className="w-[93px] h-[36px] cursor-pointer"
              onClick={() => navigate("/home")}
            />
          </div>
          <div className=" flex items-center gap-4">
            <div className=" flex gap-2">
              <div
                style={step >= 1 ? styleNumber : {}}
                className=" w-5 h-5 bg-transparent border-[1px] border-[#C3C3C3] rounded-full flex items-center justify-center text-[#C3C3C3] text-[12px] font-medium"
              >
                {step > 1 ? <img src={CheckDone} alt="CheckDone" /> : "1"}
              </div>
              <p
                style={step >= 1 ? styleContent : {}}
                className=" text-sm font-medium text-[#C3C3C3]"
              >
                Chọn cấp trường học
              </p>
            </div>
            <img src={ArrowLeft} alt="ArrowLeft" />
            <div className=" flex gap-2">
              <div
                style={step >= 2 ? styleNumber : {}}
                className=" w-5 h-5 bg-transparent border-[1px] border-[#C3C3C3] rounded-full flex items-center justify-center text-[#C3C3C3] text-[12px] font-medium"
              >
                {step > 2 ? <img src={CheckDone} alt="CheckDone" /> : "2"}
              </div>
              <p
                style={step >= 2 ? styleContent : {}}
                className=" text-sm font-medium text-[#C3C3C3]"
              >
                Tuỳ chỉnh tính năng
              </p>
            </div>
            <img src={ArrowLeft} alt="ArrowLeft" />
            <div className=" flex gap-2">
              <div
                style={step >= 3 ? styleNumber : {}}
                className=" w-5 h-5 bg-transparent border-[1px] border-[#C3C3C3] rounded-full flex items-center justify-center text-[#C3C3C3] text-[12px] font-medium"
              >
                {step > 3 ? <img src={CheckDone} alt="CheckDone" /> : "3"}
              </div>
              <p
                style={step >= 3 ? styleContent : {}}
                className=" text-sm font-medium text-[#C3C3C3]"
              >
                Thông tin hệ thống
              </p>
            </div>
          </div>
          <div className="flex items-center">
            {!loading && (
              <button
                className=" text-[15px] rounded-lg bg-transparent border-[#E2E4E9] border-[1px] py-[6px] px-3 font-normal"
                onClick={() => navigate(path)}
              >
                Trở lại trang chủ
              </button>
            )}
          </div>
        </div>
      </div>
    </header>
  );
};

export default DynamicHeader;
