import React, { useState } from "react";
import DynamicHeader from "../components/DynamicHeader";
import { useNavigate } from "react-router-dom";
const SchoolPage = () => {
  const navigate = useNavigate();
  const [activeIndex, setActiveIndex] = useState(null);

  const activeStyle = {
    background: "#f6fcf7",
    borderColor: "#1fb73b",
    color: "#1fb73b",
  };

  const items = [
    "Đại học",
    "Cao đẳng",
    "Trung tâm Đào tạo",
    "Trung học Phổ thông",
    "Trung học Cơ sở",
    "Tiểu học",
    "Mầm non",
  ];
  return (
    <>
      <DynamicHeader step={1} path="/home" />
      <div className=" py-8 flex flex-col items-center mb-11">
        <div className=" w-[500px] flex flex-col items-center gap-[24px]">
          <div className=" flex flex-col items-center text-[#2D2D2D] gap-2">
            <h1 className=" text-xl font-semibold">Chọn cấp trường</h1>
            <p className=" text-sm font-light">
              Chọn hệ thống cấp bậc trường học mà bạn muốn tạo
            </p>
          </div>
          <div className=" flex flex-col gap-[10px] self-start w-full">
            {items.map((item, index) => (
              <div
                key={index}
                className=" text-[16px] w-full rounded-[12px] font-normal border-[#8F8F8F33] border-[1px] p-[16px] hover:bg-[#DEDEDE] cursor-pointer "
                style={activeIndex === index ? activeStyle : {}}
                onClick={() => setActiveIndex(index)}
              >
                {item}
              </div>
            ))}
            <div className="  mt-2 flex gap-[16px] justify-end">
              <button
                className=" text-sm rounded-lg bg-[#20B73B] py-3 px-8 text-white font-normal"
                onClick={() => navigate("/function")}
              >
                Tiếp tục
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SchoolPage;
