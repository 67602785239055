import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import ButtonMain from "../components/ButtonMain";
import WingLeft from "../images/wingLeft.svg";
import WingRight from "../images/wingRight.svg";
const WelcomePage = () => {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    setEmail(urlParams.get('email'));
    setName(urlParams.get('name'));
  }, []);
  return (
    <>
      <Header />
      <div className=" p-4 w-screen pb-14">
        <div
          id="gradientWelcome"
          className=" h-full py-[106px] px-20 flex flex-col items-center rounded-[32px] gap-[48px]"
        >
          <div
            id="subGradientWelcome"
            className=" px-[30px] py-3 flex items-center gap-[6.25px] rounded-[10px] bg-[#F5F5F5]"
          >
            <img src={WingLeft} alt="WingLeft" />
            <p className=" text-[#20B73B] font-normal text-sm">
              Chúng tôi luôn sẵn sàng góp sức cho nền đào tạo
            </p>
            <img src={WingRight} alt="WingRight" />
          </div>
          <div className=" flex flex-col items-center">
            <h1 className=" text-[64px] font-semibold">Chào mừng</h1>
            <h1 className="  mt-0 text-[64px] font-semibold text-[#20B73B]">
              {name}
            </h1>
            <h1 className=" text-[64px] font-semibold">
              đến với giải pháp OCB Edulink
            </h1>
            <p className=" text-[#2D2D2D] font-light text-[20px] mt-[16px]">
              Thông tin hệ thống của bạn sẽ được gửi vào email {email}.
            </p>
            <p className=" text-[#2D2D2D] font-light text-[20px]">
              Vui lòng kiểm tra email.
            </p>
          </div>
          <div className="">
            <ButtonMain content={"Trở về trang chủ"} path="/home" />
          </div>
        </div>
      </div>
    </>
  );
};

export default WelcomePage;
