import React from "react";
import ImageBanner from "../images/imgBanner.svg";
import ButtonMain from "../components/ButtonMain";
import ButtonOutline from "../components/ButtonOutline";
import Tick from "../images/tick.svg";
import Header from "../components/Header";
const HomePage = () => {
  return (
    <>
      <Header />
      <div className=" p-4 w-screen h-[86vh]">
        <div
          id="gradient"
          className=" h-full py-12 px-20 flex items-center justify-around rounded-[32px] gap-[48px]"
        >
          <div className=" flex flex-col gap-[40px]">
            <div className="">
              <h1 className=" text-[64px] font-semibold">Quản lý trường học</h1>
              <h1 className="  mt-0 text-[64px] font-semibold text-[#20B73B]">
                dễ dàng và hiệu quả
              </h1>
              <p className=" text-[#2D2D2D] font-light text-[20px]">
                Giải pháp quản lý toàn diện, giúp đơn giản hóa quy trình và{" "}
                <br></br>tối ưu hóa hoạt động trường học.
              </p>
            </div>
            <div className=" flex flex-col gap-[16px]">
              <div className=" flex gap-[16px]">
                <ButtonMain
                  content={"Thử ngay"}
                  textSize="18px"
                  fontWeight="600"
                  path="/school"
                />
                <ButtonOutline content={"Tìm hiểu"} />
              </div>
              <div className=" flex flex-col gap-[8px]">
                <div className=" flex gap-1">
                  <img src={Tick} alt="tick" />
                  <p className=" text-[#20B73B] font-normal text-sm">
                    Giao diện thân thiện với người dùng
                  </p>
                </div>
                <div className=" flex gap-1">
                  <img src={Tick} alt="tick" />
                  <p className=" text-[#20B73B] font-normal text-sm">
                    Tùy chỉnh đầy đủ cho mọi nhu cầu giáo dục
                  </p>
                </div>
                <div className=" flex gap-1">
                  <img src={Tick} alt="tick" />
                  <p className=" text-[#20B73B] font-normal text-sm">
                    Hỗ trợ 24/7 từ đội ngũ chuyên nghiệp
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="">
            <img src={ImageBanner} alt="imgBanner" />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
